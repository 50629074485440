import styled from "styled-components";
import { styled as muistyled, useTheme } from "@material-ui/styles";

import {
  Button,
  Grid,
  TextField,
  Tooltip,
  InputAdornment,
  AdornmenPopover,
  Typography,
  Fade,
  Paper,
} from "@material-ui/core";
import { useState, useEffect } from "react";
const { ethers } = require("ethers");

const MintDashboard = ({
  address,
  boom,
  isGenesis,
  price,
  myNumbas,
  readContracts,
  useContractReader,
  writeContracts,
  tx,
  genesisAmount,
  numbaMinted,
  nextNumba,
}) => {
  const [currentTab, setCurrentTab] = useState("without");
  const numbaMintedValue = numbaMinted ? parseInt(numbaMinted.toString()) : 0;
  const genesisAmountValue = genesisAmount ? parseInt(genesisAmount.toString()) : 0;
  const nextNumbaValue = nextNumba && nextNumba.toString() != "0" ? nextNumba.toString() : "?";

  if (isGenesis) {
    return (
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item>
          <NumbaGenesisPrice price={price} />
        </Grid>

        <Grid item>
          <StyledGenesisSubtitle>
            {genesisAmountValue - numbaMintedValue} / {genesisAmountValue} Genesis Numba remaining
          </StyledGenesisSubtitle>
        </Grid>

        <Grid item>
          <MintGenesis address={address} boom={boom} writeContracts={writeContracts} tx={tx} price={price} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <NumbaPrice price={price} />
          </Grid>
          <Grid item>
            <NumbaPriceWithNFT price={price} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MintTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Grid>

      {currentTab == "without" && (
        <Grid item>
          <Mint address={address} writeContracts={writeContracts} tx={tx} price={price} />
        </Grid>
      )}

      {currentTab == "add" && (
        <Grid item>
          <MintByAddition
            address={address}
            readContracts={readContracts}
            useContractReader={useContractReader}
            writeContracts={writeContracts}
            nextNumbaValue={nextNumbaValue}
            tx={tx}
            price={price}
            myNumbas={myNumbas}
          />
        </Grid>
      )}

      {currentTab == "mul" && (
        <Grid item>
          <MintByMultiplication
            readContracts={readContracts}
            useContractReader={useContractReader}
            writeContracts={writeContracts}
            nextNumbaValue={nextNumbaValue}
            tx={tx}
            price={price}
            myNumbas={myNumbas}
          />
        </Grid>
      )}

      {currentTab == "pow" && (
        <Grid item>
          <MintByExponential
            readContracts={readContracts}
            useContractReader={useContractReader}
            writeContracts={writeContracts}
            nextNumbaValue={nextNumbaValue}
            tx={tx}
            price={price}
            myNumbas={myNumbas}
          />
        </Grid>
      )}
    </Grid>
  );
};

const MintTabs = ({ currentTab, setCurrentTab }) => {
  return (
    <Grid container direction="row" wrap="nowrap">
      <MintTab name={"without"} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <MintTab name={"add"} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <MintTab name={"mul"} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <MintTab name={"pow"} currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </Grid>
  );
};

const MintTab = ({ name, currentTab, setCurrentTab }) => {
  if (currentTab == name) {
    return (
      <Grid item>
        <StyledActiveMintTab>{name}</StyledActiveMintTab>
      </Grid>
    );
  }
  return (
    <Grid item>
      <StyledMintTab onClick={() => setCurrentTab(name)}>{name}</StyledMintTab>
    </Grid>
  );
};

const StyledMintTab = styled.div`
  width: 100px;
  height: 48px;
  font-size: 16px;
  border: 2px solid white;
  cursor: pointer;
  line-height: 42px;
  :hover {
    background: grey;
  }
`;

const StyledActiveMintTab = styled.div`
  width: 100px;
  height: 48px;
  font-size: 16px;
  border: 2px solid red;
  cursor: pointer;
  line-height: 42px;
  :hover {
    background: grey;
  }
`;

const NumbaPrice = ({ price }) => {
  const priceValue = price ? ethers.utils.formatEther(price) : 0;
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Mint Price without Numbas</StyledHeader>
        </Grid>
        <Grid item>{(parseFloat(priceValue) * 3).toFixed(4)} Ξ</Grid>
      </Grid>
    </div>
  );
};

const NumbaPriceWithNFT = ({ price }) => {
  const priceValue = price ? ethers.utils.formatEther(price) : 0;
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Mint Price with Numbas</StyledHeader>
        </Grid>
        <Grid item>{priceValue} Ξ</Grid>
      </Grid>
    </div>
  );
};
const NumbaGenesisPrice = ({ price }) => {
  const priceValue = price ? ethers.utils.formatEther(price) : 0;

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Mint Price</StyledHeader>
        </Grid>
        <Grid item>{priceValue} Ξ</Grid>
        <Grid item>{(parseFloat(priceValue) - 0.011) / parseFloat(priceValue) - 0.011}% to backing</Grid>
      </Grid>
    </div>
  );
};

const ClickableMyNumbas = ({ myNumbasValue, selected, setSelected }) => {
  return (
    <StyledMyNumbasContainer>
      <Grid container>
        {myNumbasValue.map(x => (
          <Grid item key={x}>
            <ClickableNumba numba={x} selected={selected} setSelected={setSelected} />
          </Grid>
        ))}
      </Grid>
    </StyledMyNumbasContainer>
  );
};

const ClickableNumba = ({ numba, selected, setSelected }) => {
  if (numba == selected) {
    return <StyledActiveNumba>{numba}</StyledActiveNumba>;
  }
  return <StyledNumba onClick={() => setSelected(numba)}>{numba}</StyledNumba>;
};

const StyledGenesisSubtitle = styled.span`
  color: grey;
  opacity: 0.9;
  font-style: italic;
`;

const StyledMyNumbasContainer = styled.div`
  padding: 30px;
`;

const StyledNumba = styled.span`
  cursor: pointer;
  padding: 8px;
`;

const StyledActiveNumba = styled.span`
  cursor: pointer;
  padding: 8px;
  border: 1px solid white;
`;

const MintGenesis = ({ address, boom, writeContracts, tx, price }) => {
  const [genesisInput, setGenesisInput] = useState("1");

  const handlePresaleInput = e => {
    if (e.target.value == "") {
      setGenesisInput("");
    }
    let value = parseInt(e.target.value);
    if (isNaN(value)) return;
    if (value > 10) {
      value = 10;
    }
    setGenesisInput(value);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>amount</Grid>
      <Grid item>
        <TextField
          color="secondary"
          label="max 10 per tx"
          variant="outlined"
          value={genesisInput}
          onChange={handlePresaleInput}
          placeholder="0"
          inputProps={{ style: { textAlign: "center", color: "#FFFFFF", fontSize: 16, width: 90 } }}
        />
      </Grid>
      {!address && (
        <Grid item>
          <i>Connect wallet first (scroll up!)</i>
        </Grid>
      )}
      <Grid item>
        <b>
          <i>
            stated gas price is an overestimation as standard estimation was wonky due on-chain random id generation
          </i>
        </b>
      </Grid>
      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="outlined"
          disabled={!address}
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const value = ethers.utils.parseEther((price * genesisInput).toString());

            const result = tx(
              writeContracts.Numba.mintGenesis(genesisInput, {
                value: price.mul(ethers.BigNumber.from(genesisInput)),
                gasLimit: 200000 * genesisInput,
              }),
              update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              },
            );
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          {boom ? "Mint Genesis" : "Soon"}
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const StyledButton = styled(Button)`
  border: #ff0000;
`;

const Mint = ({ address, writeContracts, tx, price }) => {
  console.log({ writeContracts });
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        A small buffer will be added to the transaction that will be refunded to reduce failed transactions on
        simultaneous mints.
      </Grid>
      <Grid item>
        <StyledButton
          disabled={!address}
          color={"secondary"}
          variant="outlined"
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(
              writeContracts.Numba.mint({
                value: price.mul(ethers.BigNumber.from(3)).add(ethers.utils.parseEther(".02")),
              }),
              update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              },
            );
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Mint
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const NumbaInput = ({ value, setValue, nextUsable, usable=true, myNumbasValue, isNumba = true, isDuplicate = false }) => {
  const handleNumbaInput = e => {
    if (e.target.value == "") {
      setValue("");
    }
    let value = parseInt(e.target.value);
    if (isNaN(value)) return;
    setValue(value);
  };

  return (
    <Tooltip title={`cooldown until ${nextUsable ? nextUsable : "N/A"}`}>
      <TextField
        label=""
        error={isNumba && value != "" && (!myNumbasValue.includes(value) || isDuplicate)}
        variant="outlined"
        value={value}
        onChange={handleNumbaInput}
        placeholder="0"
        inputProps={{ style: { textAlign: "center", color: usable ? "#FFFFFF" : "skyblue", fontSize: 21, width: 48 } }}
      />
    </Tooltip>
  );
};

const MintByAddition = ({ useContractReader, readContracts, writeContracts, tx, price, myNumbas, nextNumbaValue }) => {
  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];

  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [z, setZ] = useState("");

  const nextUsableX = useContractReader(readContracts, "Numba", "nextUsable", [x], 1000);
  const nextUsableY = useContractReader(readContracts, "Numba", "nextUsable", [y], 1000);
  const nextUsableZ = useContractReader(readContracts, "Numba", "nextUsable", [z], 1000);

  const nextUsableXValue = nextUsableX ? parseInt(nextUsableX.toString()) : 0;
  const nextUsableYValue = nextUsableY ? parseInt(nextUsableY.toString()) : 0;
  const nextUsableZValue = nextUsableZ ? parseInt(nextUsableZ.toString()) : 0;

  const [option, setOption] = useState(0);
  const [mintableNextNumba, setMintableNextNumba] = useState("");
  const mintableNumbas = {};

  for (let i = 0; i < myNumbasValue.length; i++) {
    for (let j = i + 1; j < myNumbasValue.length; j++) {
      for (let k = j + 1; k < myNumbasValue.length; k++) {
        const a = parseInt(myNumbasValue[i]);
        const b = parseInt(myNumbasValue[j]);
        const c = parseInt(myNumbasValue[k]);
        const result = a + b + c;

        if (result >= parseInt(nextNumbaValue)) {
          if (mintableNumbas[result]) {
            mintableNumbas[result].push([a, b, c]);
          } else {
            mintableNumbas[result] = [[a, b, c]];
          }
        }
      }
    }
  }

  const onHandleClickOption = newOption => {
    const [newX, newY, newZ] = mintableNumbas[mintableNextNumba][newOption];
    setOption(newOption);
    setX(newX);
    setY(newY);
    setZ(newZ);
  };

  const onHandleClickNextMintableNumba = numba => {
    setOption(0);
    const [newX, newY, newZ] = mintableNumbas[numba][0];
    setMintableNextNumba(numba);
    setX(newX);
    setY(newY);
    setZ(newZ);
  };

  const mintableNumbasKeys = Object.keys(mintableNumbas).map(x => parseInt(x));
  mintableNumbasKeys.sort(function (a, b) {
    return a - b;
  });
  const nextMintableNumbas = mintableNumbasKeys.slice(0, 10);

  const hasDuplicate =
    (x != "" && (x == y || x == z)) || (y != "" && (x == y || y == z)) || (z != "" && (x == z || y == z));
  const isIncorrectSum = (x ? x : 0) + (y ? y : 0) + (z ? z : 0) != nextNumbaValue;

  myNumbasValue.sort(function (a, b) {
    return a - b;
  });

  const optionsArray = [];
  if (mintableNextNumba != "") {
    for (let i = 0; i < mintableNumbas[mintableNextNumba].length; i++) {
      optionsArray.push(i);
    }
  }

    const xIsUsable = nextUsableXValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)
    const yIsUsable = nextUsableYValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)
    const zIsUsable = nextUsableZValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)

    const allUsable = (xIsUsable && yIsUsable && zIsUsable);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>Add 3 of your Numbas to equal the next Numba to mint</Grid>

      <Grid item>
        <ClickableMyNumbas myNumbasValue={myNumbasValue} selected={null} setSelected={() => {}} />
      </Grid>
      <Grid item>Next Mintable Numbas</Grid>

      <Grid item>
        <ClickableMyNumbas
          myNumbasValue={nextMintableNumbas}
          selected={mintableNextNumba}
          setSelected={onHandleClickNextMintableNumba}
        />
      </Grid>

      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <NumbaInput
              value={x}
              nextUsable={nextUsableXValue}
              usable={nextUsableXValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)}
              setValue={setX}
              myNumbasValue={myNumbasValue}
              isDuplicate={x != "" && (x == y || x == z)}
            />
          </Grid>
          <Grid item>+</Grid>
          <Grid item>
            <NumbaInput
              value={y}
              setValue={setY}
              nextUsable={nextUsableYValue}
              usable={nextUsableYValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)}
              myNumbasValue={myNumbasValue}
              isDuplicate={y != "" && (x == y || y == z)}
            />
          </Grid>
          <Grid item>+</Grid>
          <Grid item>
            <NumbaInput
              value={z}
              setValue={setZ}
              nextUsable={nextUsableZValue}
              usable={nextUsableZValue <= (x ? x : 0) + (y ? y : 0) + (z ? z : 0)}
              myNumbasValue={myNumbasValue}
              isDuplicate={z != "" && (x == z || y == z)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>=</Grid>
      <Grid item>{(x ? x : 0) + (y ? y : 0) + (z ? z : 0)}</Grid>
      <Grid item>
        <i>{nextNumbaValue - ((x ? x : 0) + (y ? y : 0) + (z ? z : 0))} required</i>
      </Grid>
      {mintableNextNumba != "" && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <b>{mintableNumbas[mintableNextNumba].length} options</b>
            </Grid>
            <Grid item>
              <ClickableMyNumbas myNumbasValue={optionsArray} selected={option} setSelected={onHandleClickOption} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="outlined"
          disabled={
            !myNumbasValue.includes(x) ||
            !myNumbasValue.includes(y) ||
            !myNumbasValue.includes(z) ||
            hasDuplicate ||
            isIncorrectSum || 
            !allUsable
            
          }
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.mintByAddition(x, y, z, { value: price }), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Mint By Addition
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const MintByMultiplication = ({
  writeContracts,
  tx,
  price,
  myNumbas,
  nextNumbaValue,
}) => {
  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];
  const [option, setOption] = useState(0);

  const [x, setX] = useState("");
  const [y, setY] = useState("");


  const [mintableNextNumba, setMintableNextNumba] = useState("");
  const mintableNumbas = {};
  for (let i = 0; i < myNumbasValue.length; i++) {
    for (let j = i + 1; j < myNumbasValue.length; j++) {
      const a = parseInt(myNumbasValue[i]);
      const b = parseInt(myNumbasValue[j]);
      const result = a * b;

      if (result >= parseInt(nextNumbaValue)) {
        if (mintableNumbas[result]) {
          mintableNumbas[result].push([a, b]);
        }
        mintableNumbas[result] = [[a, b]];
      }
    }
  }

  const onHandleClickOption = newOption => {
    const [newX, newY] = mintableNumbas[mintableNextNumba][newOption];
    setOption(newOption);
    setX(newX);
    setY(newY);
  };

  const onHandleClickNextMintableNumba = numba => {
    setOption(0);
    const [newX, newY] = mintableNumbas[numba][0];
    setMintableNextNumba(numba);
    setX(newX);
    setY(newY);
  };

  const isIncorrectProduct = (x ? x : 0) * (y ? y : 0) != nextNumbaValue;
  myNumbasValue.sort(function (a, b) {
    return a - b;
  });

  const mintableNumbasKeys = Object.keys(mintableNumbas).map(x => parseInt(x));
  mintableNumbasKeys.sort(function (a, b) {
    return a - b;
  });
  const nextMintableNumbas = mintableNumbasKeys.slice(0, 10);

  const optionsArray = [];
  if (mintableNextNumba != "") {
    for (let i = 0; i < mintableNumbas[mintableNextNumba].length; i++) {
      optionsArray.push(i);
    }
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>Multiply 2 of your Numbas to equal the next Numba to mint</Grid>
      <Grid item>
        <ClickableMyNumbas myNumbasValue={myNumbasValue} selected={null} setSelected={() => {}} />
      </Grid>
      <Grid item>Next Mintable Numbas</Grid>
      <Grid item>
        <ClickableMyNumbas
          myNumbasValue={nextMintableNumbas}
          selected={mintableNextNumba}
          setSelected={onHandleClickNextMintableNumba}
        />
      </Grid>

      <Grid item>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <NumbaInput
              value={x}
              setValue={setX}
              myNumbasValue={myNumbasValue}
            />
          </Grid>
          <Grid item>x</Grid>
          <Grid item>
            <NumbaInput
              value={y}
              setValue={setY}
              myNumbasValue={myNumbasValue}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>=</Grid>
      <Grid item>{x * y}</Grid>
      {mintableNextNumba != "" && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <b>{mintableNumbas[mintableNextNumba].length} options</b>
            </Grid>
            <Grid item>
              <ClickableMyNumbas myNumbasValue={optionsArray} selected={option} setSelected={onHandleClickOption} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="contained"
          disabled={!myNumbasValue.includes(x) || !myNumbasValue.includes(y) || isIncorrectProduct}
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.mintByMultiplication(x, y, { value: price }), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Mint By Multiplication
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const MintByExponential = ({
  useContractReader,
  readContracts,
  writeContracts,
  tx,
  price,
  myNumbas,
  nextNumbaValue,
}) => {
  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];
  const [option, setOption] = useState(0);

  const [x, setX] = useState("");
  const [y, setY] = useState("");

  myNumbasValue.sort(function (a, b) {
    return a - b;
  });

  const [mintableNextNumba, setMintableNextNumba] = useState("");
  const mintableNumbas = {};
  for (let i = 0; i < myNumbasValue.length; i++) {
    for (let j = 2; j < 12; j++) {
      const a = parseInt(myNumbasValue[i]);
      const b = j;

      const result = a ** b;

      if (result >= parseInt(nextNumbaValue) && result < 100000) {
        if (mintableNumbas[result]) {
          mintableNumbas[result].push([a, b]);
        }
        mintableNumbas[result] = [[a, b]];
      }
    }
  }
  const onHandleClickOption = newOption => {
    const [newX, newY] = mintableNumbas[mintableNextNumba][newOption];
    setOption(newOption);
    setX(newX);
    setY(newY);
  };

  const onHandleClickNextMintableNumba = numba => {
    setOption(0);
    const [newX, newY] = mintableNumbas[numba][0];
    setMintableNextNumba(numba);
    setX(newX);
    setY(newY);
  };

  const mintableNumbasKeys = Object.keys(mintableNumbas).map(x => parseInt(x));
  mintableNumbasKeys.sort(function (a, b) {
    return a - b;
  });
  const nextMintableNumbas = mintableNumbasKeys.slice(0, 10);

  const isIncorrectProduct = (x ? x : 0) ** ((y ? y : 0) != nextNumbaValue);

  const optionsArray = [];
  if (mintableNextNumba != "") {
    for (let i = 0; i < mintableNumbas[mintableNextNumba].length; i++) {
      optionsArray.push(i);
    }
  }
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>Square, Cube, etc one of your Numbas to equal the next Numba to mint</Grid>
      <Grid item>
        <ClickableMyNumbas myNumbasValue={myNumbasValue} selected={null} setSelected={() => {}} />
      </Grid>

      <Grid item>Next Mintable Numbas</Grid>
      <Grid item>
        <ClickableMyNumbas
          myNumbasValue={nextMintableNumbas}
          selected={mintableNextNumba}
          setSelected={onHandleClickNextMintableNumba}
        />
      </Grid>

      <Grid item>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <NumbaInput value={x} setValue={setX} myNumbasValue={myNumbasValue} />
          </Grid>
          <Grid item>^</Grid>
          <Grid item>
            <NumbaInput value={y} setValue={setY} myNumbasValue={myNumbasValue} isNumba={false} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>=</Grid>
      <Grid item>{(x ? x : 0) ** (y ? y : 0)}</Grid>
      {mintableNextNumba != "" && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <b>{mintableNumbas[mintableNextNumba].length} options</b>
            </Grid>
            <Grid item>
              <ClickableMyNumbas myNumbasValue={optionsArray} selected={option} setSelected={onHandleClickOption} />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <StyledButton
          variant="contained"
          disabled={!myNumbasValue.includes(x) || isIncorrectProduct}
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.mintByExponential(x, y, { value: price }), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Mint By Exponential
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const StyledHeader = styled.span`
  font-weight: bold;
`;

export default MintDashboard;
