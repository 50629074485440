import styled from "styled-components";
import { styled as muistyled, useTheme } from "@material-ui/styles";

import { Button, Grid, TextField, InputAdornment, AdornmenPopover, Typography, Fade, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
const { ethers } = require("ethers");

const BigNumbaDashboard = ({ readContracts, useContractReader, writeContracts, tx, address, myNumbas }) => {
  const numbaAddress = useContractReader(readContracts, "BigNumba", "numbaAddress", 10000001000000);
  const biggest = useContractReader(readContracts, "BigNumba", "biggest", 3000);
  const biggestAt = useContractReader(readContracts, "BigNumba", "biggestAt", 3000);
  const requiredDuration = useContractReader(readContracts, "BigNumba", "requiredDuration", 10000001000000);

  const bounty = useContractReader(readContracts, "BigNumba", "bounty", 10000001000000);
  const fee = useContractReader(readContracts, "BigNumba", "fee", 10000001000000);
  const nextRoundBounty = useContractReader(readContracts, "BigNumba", "nextRoundBounty", 10000001000000);
  const futureRoundsBountyReserve = useContractReader(
    readContracts,
    "BigNumba",
    "futureRoundsBountyReserve",
    10000001000000,
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>Hold and flex the biggest Numba for 12 hours and win the bounty!</Grid>
      <Grid item>
        <BigNumbaInfo
          myNumbas={myNumbas}
          numbaAddress={numbaAddress ? numbaAddress.toString() : 0}
          biggest={biggest ? parseInt(biggest.toString()) : 0}
          biggestAt={biggestAt ? parseInt(biggestAt.toString()) : 0}
          requiredDuration={requiredDuration ? parseInt(requiredDuration.toString()) : 0}
          bounty={bounty ? ethers.utils.formatEther(bounty) : 0}
          nextRoundBounty={nextRoundBounty ? ethers.utils.formatEther(nextRoundBounty) : 0}
          fee={fee ? ethers.utils.formatEther(fee) : 0}
          futureRoundsBountyReserve={
            futureRoundsBountyReserve ? ethers.utils.formatEther(futureRoundsBountyReserve) : 0
          }
        />
      </Grid>

      <IAMBIGGEST
        biggestAt={biggestAt ? parseInt(biggestAt.toString()) : 0}
        requiredDuration={requiredDuration ? parseInt(requiredDuration.toString()) : 0}
        biggest={biggest}
        myNumbas={myNumbas}
        biggest={biggest ? parseInt(biggest.toString()) : 0}
        writeContracts={writeContracts}
        tx={tx}
        fee={fee}
      />

      <WinnerClaim
        biggestAt={biggestAt ? parseInt(biggestAt.toString()) : 0}
        requiredDuration={requiredDuration ? parseInt(requiredDuration.toString()) : 0}
        writeContracts={writeContracts}
        tx={tx}
        fee={fee}
      />

      <DonateToBounty
        biggestAt={biggestAt ? parseInt(biggestAt.toString()) : 0}
        requiredDuration={requiredDuration ? parseInt(requiredDuration.toString()) : 0}
        writeContracts={writeContracts}
        tx={tx}
      />
      <Grid item>
        <BigNumbaInfo2
          myNumbas={myNumbas}
          numbaAddress={numbaAddress ? numbaAddress.toString() : 0}
          biggest={biggest ? parseInt(biggest.toString()) : 0}
          biggestAt={biggestAt ? parseInt(biggestAt.toString()) : 0}
          requiredDuration={requiredDuration ? parseInt(requiredDuration.toString()) : 0}
          bounty={bounty ? ethers.utils.formatEther(bounty) : 0}
          nextRoundBounty={nextRoundBounty ? ethers.utils.formatEther(nextRoundBounty) : 0}
          fee={fee ? ethers.utils.formatEther(fee) : 0}
          futureRoundsBountyReserve={
            futureRoundsBountyReserve ? ethers.utils.formatEther(futureRoundsBountyReserve) : 0
          }
        />
      </Grid>

      {/* <Grid item>
                <SetNumbaAddress
                    writeContracts={writeContracts}
                    tx={tx}
                />
            </Grid>

            <Grid item>
                <IncreaseBountyFromReserve
                    writeContracts={writeContracts}
                    tx={tx}
                    fee={fee}
                />
            </Grid>
            <Grid item>
                <SetNextRoundFee
                    writeContracts={writeContracts}
                    tx={tx}
                    fee={fee}
                />
            </Grid>
            <Grid item>
                <SetNextRoundDuration
                    writeContracts={writeContracts}
                    tx={tx}
                    fee={fee}
                />
            </Grid> */}
      {/* <Grid item>
                <TransferReserveToBacking
                    writeContracts={writeContracts}
                    tx={tx}
                    fee={fee}
                />
            </Grid> */}
    </Grid>
  );
};

const SetNumbaAddress = ({ writeContracts, tx }) => {
  const [address, setAddress] = useState();

  const handleAddressInput = e => {
    const value = e.target.value;
    setAddress(value);
  };

  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <h3>Set Numba Address</h3>
        </Grid>
        <Grid item>
          <TextField
            label="numba address"
            onChange={handleAddressInput}
            value={address}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const result = tx(writeContracts.BigNumba.setNumba(address), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            set numba address
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const IAMBIGGEST = ({ writeContracts, tx, fee, myNumbas, biggest, biggestAt, requiredDuration }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      const timeLeft = biggestAt + requiredDuration - Date.now() / 1000;
      setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
    }, 100);
    return () => {
      clearInterval(myInterval);
    };
  });

  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];
  myNumbasValue.sort(function (a, b) {
    return a - b;
  });
  const [id, setId] = useState(myNumbasValue.length ? myNumbasValue[myNumbasValue.length - 1] : null);
  if (timeRemaining == 0 && biggest != 0) {
    return null;
  }
  const handleIdInput = e => {
    const value = e.target.value;
    setId(value);
  };

  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Numba"
            onChange={handleIdInput}
            value={id}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              if (!id || id < biggest) return;
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const result = tx(writeContracts.BigNumba.IAmBiggest(id, { value: fee }), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            flex big numba
          </StyledButton>
        </Grid>
        {id && id < biggest && (
          <Grid item>
            <i>that's too small</i>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const WinnerClaim = ({ writeContracts, tx, biggestAt, requiredDuration }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timeRemainingToClaim, setTimeRemainingToClaim] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      const timeLeft = biggestAt + requiredDuration - Date.now() / 1000;
      const timeLeftToClaim = biggestAt + requiredDuration * 2 - Date.now() / 1000;

      setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
      setTimeRemainingToClaim(timeLeftToClaim > 0 ? timeLeftToClaim : 0);
    }, 100);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (timeRemaining != 0) {
    return null;
  }

  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const result = tx(writeContracts.BigNumba.claimBounty(), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            claim winner bounty
          </StyledButton>
        </Grid>
        <Grid item>
          <i>winner has {timeRemainingToClaim ? timeRemainingToClaim.toFixed(1) : 0} seconds to claim</i>
        </Grid>
        <Grid item>
          <i>claiming the bounty will send the funds to the winner and start the next round</i>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DonateToBounty = ({ writeContracts, tx, biggestAt, requiredDuration }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      const timeLeft = biggestAt + requiredDuration - Date.now() / 1000;
      setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
    }, 100);
    return () => {
      clearInterval(myInterval);
    };
  });

  const [amount, setAmount] = useState();
  if (timeRemaining == 0) {
    return null;
  }

  const handleAmountInput = e => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label="amount in ETH"
            onChange={handleAmountInput}
            value={amount}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              if (!amount || timeRemaining == 0) {
                return;
              }
              const value = ethers.utils.parseEther(amount.toString());
              const result = tx(writeContracts.BigNumba.donateToBounty(value, { value: value }), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            donate to bounty
          </StyledButton>
        </Grid>
        {timeRemaining == 0 && (
          <Grid item>
            <i>please wait until the current winner has claimed </i>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const SetNextRoundFee = ({ writeContracts, tx }) => {
  const [amount, setAmount] = useState();

  const handleAmountInput = e => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <h3>Set Next Round Fee</h3>
        </Grid>
        <Grid item>
          <TextField
            label="donation amount in ETH"
            onChange={handleAmountInput}
            value={amount}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const value = ethers.utils.parseEther(amount.toString());
              const result = tx(writeContracts.BigNumba.setNextRoundFee(value), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            set next round fee
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const IncreaseBountyFromReserve = ({ writeContracts, tx }) => {
  const [amount, setAmount] = useState();

  const handleAmountInput = e => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <h3>Set Next Round Fee</h3>
        </Grid>
        <Grid item>
          <TextField
            label="donation amount in ETH"
            onChange={handleAmountInput}
            value={amount}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const value = ethers.utils.parseEther(amount.toString());
              const result = tx(writeContracts.BigNumba.increaseBountyFromReserve(value), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            increase bounty from bounty reserve
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

// const TransferReserveToBacking = ({  writeContracts, tx }) => {
//     const [amount, setAmount] = useState();

//     const handleAmountInput = (e) => {
//         const value = e.target.value
//         setAmount(value)
//     }

//     return (

//             <Grid item>
//                 <Grid container direction="column">
//                     <Grid item>
//                     <h3>Transfer Bounty Reserve to Backing </h3>
//                     </Grid>

//                     <Grid item>
//                         <StyledButton
//                         variant="outlined"
//                         color="secondary"
//                             onClick={async () => {
//                             /* look how you call setPurpose on your contract: */
//                             /* notice how you pass a call back for tx updates too */
//                             const result = tx(writeContracts.BigNumba.transferBountyToNumbaBacking(), update => {
//                                 console.log("📡 Transaction Update:", update);
//                                 if (update && (update.status === "confirmed" || update.status === 1)) {
//                                 console.log(" 🍾 Transaction " + update.hash + " finished!");
//                                 console.log(
//                                     " ⛽️ " +
//                                     update.gasUsed +
//                                     "/" +
//                                     (update.gasLimit || update.gas) +
//                                     " @ " +
//                                     parseFloat(update.gasPrice) / 1000000000 +
//                                     " gwei",
//                                 );
//                                 }
//                             });
//                             console.log("awaiting metamask/web3 confirm result...", result);
//                             console.log(await result);
//                             }}
//                         >
//                             transfer bounty reserve to backing
//                         </StyledButton>
//                     </Grid>

//                 </Grid>

//             </Grid>
//     )
// }

const SetNextRoundDuration = ({ writeContracts, tx }) => {
  const [amount, setAmount] = useState();

  const handleAmountInput = e => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <Grid item>
      <Grid container direction="column">
        <Grid item>
          <h3>Set Next Round Duration</h3>
        </Grid>
        <Grid item>
          <TextField
            label="donation amount in ETH"
            onChange={handleAmountInput}
            value={amount}
            color="secondary"
            inputProps={{ style: { border: "1px solid #FFFFFF", textAlign: "center", color: "#FFFFFF" } }}
          />
        </Grid>

        <Grid item>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              const value = parseInt(amount);
              const result = tx(writeContracts.BigNumba.setNextRoundRequiredDuration(value), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            set next round duration
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BigNumbaInfo = ({
  numbaAddress,
  biggest,
  biggestAt,
  requiredDuration,
  bounty,
  fee,
  nextRoundBounty,
  futureRoundsBountyReserve,
  myNumbas,
}) => {
  const [timeRemaining, setTimeRemaining] = useState();
  useEffect(() => {
    let myInterval = setInterval(() => {
      const timeLeft = biggestAt + requiredDuration - Date.now() / 1000;
      setTimeRemaining(timeLeft > 0 ? timeLeft : 0);
    }, 100);
    return () => {
      clearInterval(myInterval);
    };
  });

  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];
  myNumbasValue.sort(function (a, b) {
    return a - b;
  });

  return (
    <Grid container direction="column" spacing={1}>
      {/* <Grid item>
                Numba Address {numbaAddress}
            </Grid> */}
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <h2 style={{ color: "white" }}>Bounty</h2>
          </Grid>
          <Grid item>
            <h3 style={{ color: "white" }}>{bounty ? parseFloat(bounty).toFixed(4) : 0} Ξ</h3>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <h2 style={{ color: "white" }}>Biggest Numba</h2>
          </Grid>
          <Grid item>
            <h3 style={{ color: "white" }}>{biggest}</h3>
          </Grid>
          {myNumbasValue.includes(biggest) && (
            <Grid item>
              <h4 style={{ color: "red" }}>{"That's you!"}</h4>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <h2 style={{ color: "white" }}>Time Left to Win</h2>
          </Grid>
          <Grid item>
            <h3 style={{ color: "white" }}>{timeRemaining ? timeRemaining.toFixed(2) : 0} seconds</h3>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <i>participation fee</i>
          </Grid>
          <Grid item>{fee ? parseFloat(fee).toFixed(4) : 0} Ξ</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BigNumbaInfo2 = ({
  numbaAddress,
  biggest,
  biggestAt,
  requiredDuration,
  bounty,
  fee,
  nextRoundBounty,
  futureRoundsBountyReserve,
  myNumbas,
}) => {
  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>Required Duration {requiredDuration} seconds</Grid>
      <Grid item>Next Round Bounty {nextRoundBounty ? parseFloat(nextRoundBounty).toFixed(4) : 0} Ξ</Grid>
      <Grid item>
        Bounty Reserve {futureRoundsBountyReserve ? parseFloat(futureRoundsBountyReserve).toFixed(4) : 0} Ξ
      </Grid>
    </Grid>
  );
};
const StyledButton = styled(Button)`
  border: #ff0000;
`;
export default BigNumbaDashboard;
