import styled from "styled-components";
import { styled as muistyled, useTheme } from "@material-ui/styles";

import { Button, Grid, TextField, InputAdornment, AdornmenPopover, Typography, Fade, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
const { ethers } = require("ethers");

import { About, MintDashboard, RedeemDashboard, BigNumbaDashboard } from "./components";

const Home = ({ readContracts, useContractReader, writeContracts, tx, address }) => {
  const [currentTab, setCurrentTab] = useState("ABOUT");

  // const boom = useContractReader(readContracts, "Numba", "boom");
  const nextNumba = useContractReader(readContracts, "Numba", "nextNumba", 10000001000000);
  const price = useContractReader(readContracts, "Numba", "price", 10000001000000);
  const totalSupply = useContractReader(readContracts, "Numba", "totalSupply", 10000001000000);
  const numbaMinted = useContractReader(readContracts, "Numba", "numbaMinted", 10000001000000);
  const numbaBurnt = useContractReader(readContracts, "Numba", "numbaBurnt", 10000001000000);
  const backingPerToken = useContractReader(readContracts, "Numba", "backingPerToken", 10000001000000);
  const myNumbas = useContractReader(readContracts, "Numba", "ownedTokenIds", [address], 10000001000000);
  const holderClaimable = useContractReader(readContracts, "Numba", "holderClaimable", [address], 10000001000000);
  const genesisAmount = useContractReader(readContracts, "Numba", "GENESIS_AMOUNT", 10000001000000);
  const devWithdrawable = useContractReader(readContracts, "Numba", "devWithdrawable", 10000001000000);
  const etherBalance = useContractReader(readContracts, "Numba", "etherBalance", 10000001000000);

  const isGenesis = numbaMinted && genesisAmount && numbaMinted.lt(genesisAmount);

  return (
    <StyledDiv>
      <StyledPage container direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Hero nextNumba={nextNumba} numbaMinted={numbaMinted} isGenesis={isGenesis} genesisAmount={genesisAmount} />
        </Grid>

        <Grid item>
          <Metrics
            etherBalance={etherBalance}
            devWithdrawable={devWithdrawable}
            totalSupply={totalSupply}
            numbaMinted={numbaMinted}
            backingPerToken={backingPerToken}
            numbaBurnt={numbaBurnt}
            price={price}
            isGenesis={isGenesis}
          />
        </Grid>

        <Grid item>
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </Grid>

        {currentTab == "MINT" && (
          <MintDashboard
            price={price}
            writeContracts={writeContracts}
            tx={tx}
            isGenesis={isGenesis}
            nextNumba={nextNumba}
            myNumbas={myNumbas}
            genesisAmount={genesisAmount}
            numbaMinted={numbaMinted}
            boom={true}
            address={address}
            readContracts={readContracts}
            useContractReader={useContractReader}
          />
        )}
        {currentTab == "REDEEM" && (
          <RedeemDashboard
            price={price}
            writeContracts={writeContracts}
            tx={tx}
            isGenesis={isGenesis}
            myNumbas={myNumbas}
            holderClaimable={holderClaimable}
            backingPerToken={backingPerToken}
          />
        )}

        {currentTab == "ABOUT" && <About genesisAmount={genesisAmount} />}

        {currentTab == "BIG NUMBA" && (
          <BigNumbaDashboard
            readContracts={readContracts}
            useContractReader={useContractReader}
            writeContracts={writeContracts}
            tx={tx}
            myNumbas={myNumbas}
            address={address}
          />
        )}
      </StyledPage>
      {/* 
      <Grid item>
          <DevWithdraw
              writeContracts={writeContracts}
              tx={tx}
          />
        </Grid> */}
      {/* <Grid item>
          <BigBang
              writeContracts={writeContracts}
              tx={tx}
          />
        </Grid>
 */}
    </StyledDiv>
  );
};


const Hero = ({ nextNumba, isGenesis, totalMinted, genesisAmount }) => {
  const totalMintedValue = totalMinted ? parseInt(totalMinted.toString()) : 0;
  const genesisAmountValue = genesisAmount ? parseInt(genesisAmount.toString()) : 0;
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Title />
      </Grid>
      <Grid item>
        <NextNumba nextNumba={nextNumba} />
      </Grid>
      {isGenesis && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <StyledGenesisTitle>GENESIS PHASE</StyledGenesisTitle>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const StyledGenesisTitle = styled.span`
  color: red;
  opacity: 0.9;
  font-weight: bold;
  font-style: italic;
`;

const StyledGenesisSubtitle = styled.span`
  color: grey;
  opacity: 0.9;
  font-style: italic;
`;

const Metrics = ({
  etherBalance,
  devWithdrawable,
  totalSupply,
  numbaMinted,
  backingPerToken,
  numbaBurnt,
  price,
  isGenesis,
}) => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container direction="row" spacing={4} wrap="nowrap">
          <Grid item>
            <NumbaMinted numbaMinted={numbaMinted} />
          </Grid>

          <Grid item>
            <BackingPerToken backingPerToken={backingPerToken} />
          </Grid>

          <Grid item>
            <NumbaBurnt numbaBurnt={numbaBurnt} />
          </Grid>

          {/* <Grid item>
            <TotalSupply
              totalSupply={totalSupply}
            />
          </Grid>
          <Grid item>
            <DevWithdrawable
              devWithdrawable={devWithdrawable}
            />
          </Grid>
          <Grid item>
            <EtherBalance
              etherBalance={etherBalance}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Title = ({}) => {
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledTitle>Numba</StyledTitle>
        </Grid>
        <Grid item>
          <span>
            <i>go up!</i>
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

const StyledTitle = styled.span`
  font-size: 40px;
  font-weight: bold;
  // font-style: italic;
`;

const ClickableMyNumbas = ({ myNumbasValue, selected, setSelected }) => {
  return (
    <StyledMyNumbasContainer>
      <Grid container>
        {myNumbasValue.map(x => (
          <Grid item key={x}>
            <ClickableNumba numba={x} selected={selected} setSelected={setSelected} />
          </Grid>
        ))}
      </Grid>
    </StyledMyNumbasContainer>
  );
};

const StyledMyNumbasContainer = styled.div`
  padding: 30px;
`;

const ClickableNumba = ({ numba, selected, setSelected }) => {
  if (numba == selected) {
    return <StyledActiveNumba>{numba}</StyledActiveNumba>;
  }
  return <StyledNumba onClick={() => setSelected(numba)}>{numba}</StyledNumba>;
};

const StyledNumba = styled.span`
  cursor: pointer;
  padding: 8px;
`;

const StyledActiveNumba = styled.span`
  cursor: pointer;
  padding: 8px;
  border: 1px solid white;
`;



const TotalSupply = ({ totalSupply }) => {
  const totalSupplyValue = totalSupply ? totalSupply.toString() : 0;

  return <div>Total Supply: {totalSupplyValue}</div>;
};

const DevWithdrawable = ({ devWithdrawable }) => {
  const devWithdrawableValue = devWithdrawable ? ethers.utils.formatEther(devWithdrawable) : 0;

  return <div>devWithdrawableValue: {devWithdrawableValue}</div>;
};

const EtherBalance = ({ etherBalance }) => {
  const etherBalanceValue = etherBalance ? ethers.utils.formatEther(etherBalance) : 0;

  return <div>etherBalanceValue: {etherBalanceValue}</div>;
};

const NextNumba = ({ nextNumba }) => {
  const nextNumbaValue = nextNumba && nextNumba.toString() != "0" ? nextNumba.toString() : "?";

  return (
    <div>
      <StyledNextNumbaContainer square elevation={3}>
        <StyledNextNumbaGrid container direction="column" justifyContent="center">
          <Grid item>Next Numba</Grid>
          <Grid item>
            <StyledNextNumba>{nextNumbaValue}</StyledNextNumba>
          </Grid>
        </StyledNextNumbaGrid>
      </StyledNextNumbaContainer>
    </div>
  );
};

const StyledNextNumbaGrid = styled(Grid)`
  height: 330px;
  width: 330px;
`;

const StyledNextNumbaContainer = styled(Paper)`
  height: 330px;
  width: 330px;
`;

const StyledNextNumba = styled.span`
  font-size: 90px;
`;
const NumbaPrice = ({ price }) => {
  const priceValue = price ? ethers.utils.formatEther(price) : 0;
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Mint Price without Numbas</StyledHeader>
        </Grid>
        <Grid item>{(parseFloat(priceValue) * 3).toFixed(4)} Ξ</Grid>
      </Grid>
    </div>
  );
};



const BackingPerToken = ({ backingPerToken }) => {
  const backingPerTokenValue = backingPerToken ? parseFloat(ethers.utils.formatEther(backingPerToken)).toFixed(4) : 0;
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Backing</StyledHeader>
        </Grid>
        <Grid item>{backingPerTokenValue} Ξ</Grid>
      </Grid>
    </div>
  );
};

const NumbaMinted = ({ numbaMinted }) => {
  const numbaMintedValue = numbaMinted ? numbaMinted.toString() : 0;

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Minted</StyledHeader>
        </Grid>
        <Grid item>{numbaMintedValue}</Grid>
      </Grid>
    </div>
  );
};

const NumbaBurnt = ({ numbaBurnt }) => {
  const numbaBurntValue = numbaBurnt ? numbaBurnt.toString() : 0;

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <StyledHeader>Burned</StyledHeader>
        </Grid>
        <Grid item>{numbaBurntValue}</Grid>
      </Grid>
    </div>
  );
};

const StyledHeader = styled.span`
  font-weight: bold;
`;

const Tabs = ({ currentTab, setCurrentTab }) => {
  return (
    <Grid container direction="row" wrap="nowrap" spacing={3}>
      <Grid item>
        <TabItem name="ABOUT" currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Grid>
      <Grid item>
        <TabItem name="MINT" currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Grid>
      <Grid item>
        <TabItem name="REDEEM" currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Grid>
      <Grid item>
        <TabItem name="BIG NUMBA" currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Grid>
    </Grid>
  );
};

const StyledTab = styled.div`
  padding: 24px;
  padding-bottom: 12px;
  cursor: pointer;
  margin-bottom: 10px;

  :hover {
    color: grey;
  }
`;

const StyledActiveTab = styled.div`
  padding: 24px;
  padding-bottom: 12px;
  cursor: pointer;
  border-bottom: 1px solid red;
  margin-bottom: 10px;
  :hover {
    color: grey;
  }
`;

const TabItem = ({ name, currentTab, setCurrentTab }) => {
  if (name == currentTab) {
    return <StyledActiveTab onClick={() => setCurrentTab(name)}>{name}</StyledActiveTab>;
  }

  return <StyledTab onClick={() => setCurrentTab(name)}>{name}</StyledTab>;
};

const MyNumbas = ({ readContracts, useContractReader, myNumbas }) => {
  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];

  myNumbasValue.sort(function (a, b) {
    return a - b;
  });

  return <div>My Numbas: {myNumbasValue.map(x => x + " ")}</div>;
};

const HolderClaimable = ({ readContracts, useContractReader, holderClaimable }) => {
  const holderClaimableValue = holderClaimable ? ethers.utils.formatEther(holderClaimable) : 0;
  return <div>Total Claimable: {holderClaimableValue} Ξ</div>;
};


const DevWithdraw = ({ writeContracts, tx }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="contained"
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.devWithdraw(), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          devWithdraw
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const BigBang = ({ writeContracts, tx }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="contained"
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.bigBang(), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          BigBang
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const ClaimAndBurn = ({ writeContracts, tx, selectedNumba, backingPerTokenValue }) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {selectedNumba ? <i>You will receive {backingPerTokenValue} Ξ</i> : <i> Click to select a NFT to burn </i>}
      </Grid>

      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="outlined"
          disabled={!selectedNumba}
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.claimAndBurn(selectedNumba), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Claim Backing And Burn Numba
        </StyledButton>
      </Grid>
    </Grid>
  );
};

const NumbaInput = ({ value, setValue, myNumbasValue, isNumba = true, isDuplicate = false }) => {
  const handleNumbaInput = e => {
    if (e.target.value == "") {
      setValue("");
    }
    let value = parseInt(e.target.value);
    if (isNaN(value)) return;
    setValue(value);
  };

  return (
    <TextField
      label=""
      error={isNumba && value != "" && (!myNumbasValue.includes(value) || isDuplicate)}
      variant="outlined"
      value={value}
      onChange={handleNumbaInput}
      placeholder="0"
      inputProps={{ style: { textAlign: "center", color: "#FFFFFF", fontSize: 21, width: 48 } }}
    />
  );
};



const StyledDiv = styled.div`
  // max-height: 100vh;
  padding-top: 20px;
`;

const StyledPage = styled(Grid)``;

export default Home;
