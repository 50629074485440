import styled from "styled-components";
import { styled as muistyled, useTheme } from "@material-ui/styles";

import { Button, Grid, TextField, InputAdornment, AdornmenPopover, Typography, Fade, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
const { ethers } = require("ethers");




const About = ({}) => {
  return (
    <Grid container direction="column" justifyContent="center" spacing={3}>
      <AboutSection
        header="tl;dr"
        body={`
        Numba go up.
        `}
      />

      <AboutSection header="Contract Address" body={`0xc315C1982efaB100b4A3EcA4035567358f85bBB2`} />

      <AboutSection
        header="Introduction"
        body={`
        Distilled as an NFT collection of mintable numbers,
        Numba is an interpretation of some of the most
        popular and controversial mechanisms in web3 that have
        surfaced this cycle. As such, Numba is a critique, an exploration, and 
        a tacit embrace of the space all at the same time.
        `}
      />

      <AboutSection
        header="Genesis"
        body={`
        1000 Numbas will be mintable during the Genesis phase of the project.
        During this phase, the number assigned to each Numba will be randomly distributed,
        such that a minter will have an even chance to mint any Numba between 1 - 1000.
        The mint price will be 0.033 ETH, of which 0.022 ETH will go towards backing each NFT, 
        thereby giving each NFT 2/3 backing during Genesis, and higher thereafter. 
        `}
      />

      <AboutSection
        header="Minting - Post Genesis"
        body={`
        After the Genesis phase, Numbas can be minted sequentially, i.e. 1001, 1002, 1003.
        With each mint, the price to mint the next Numba will go up by 0.001 ETH. There are two ways
        to mint the next Numba: 1. using existing Numbas, or 2. without existing Numbas 
        but at a 3x premium. After one thousand mints, the price to mint will have gone up 1 ETH 
        (3 ETH for those minting without Numbas).
        `}
      />

      <AboutSection
        header="Arithmetic Minting - Minting With Existing Numbas"
        body={`
        As a way to control supply and provide utility to existing Numbas, 
        the most cost efficient way to mint the next Numba is by using existing Numbas.
        Arithmetic minting offers 3 avenues to mint using existing Numbas, 1) add 3 
        owned Numba to equal the next Numba, 2) multiply two owned Numba to produce
        the next Numba, 3) raise a owned Numba to any power to produce the next Numba.
        For example, a holder who holds Numba 2, Numba 10, and Numba 989, will be able to 
        mint Numba 1001. With the exception of a constant 0.011 ETH, all funds are used to increase the backing of  
        every Numba. In the case of minting using addition, there will be a cooldown of 20 Numbas before a Numba can be used again for addition.
        `}
      />

      <AboutSection
        header="Minting Without Existing Numbas"
        body={`
        Anyone will still be able to mint the next Numba, however, the price to 
        mint the next Numba without holding existing Numbas will be at a 3x premium of the current mint price. 
        With the exception of a constant 0.011 ETH, all funds are used to evenly backed 
        every Numbas. Any increase in supply therefore greatly benefits existing holders, 
        by increasing the backing of each NFT.
        `}
      />

      <AboutSection
        header="Backing and Redemption"
        body={`
        Backing is increased in two ways: Numba being minted at above the backing price, and
        50% of the creator royalty collected will be used to directly support backing. A Numba holder may decide
        to redeem and burn their Numba who the backing price at any time. However, in doing so, they 
        will permanently reduce the supply and the existence of that specific Numba. With each redemption, 
        the mint price will be reduced by 0.001 ETH. As Numbas go up, an ever-increasing proportion of each mint
        be used to support backing.
        `}
      />
    </Grid>
  );
};

const AboutSection = ({ header, body }) => {
  return (
    <Grid item>
      <StyledAboutContainer>
        <Grid container direction="column" spacing={2} alignItems="flex-start">
          <Grid item>
            <StyledAboutHeader>{header}</StyledAboutHeader>
          </Grid>

          <Grid item>
            <StyledAboutBody>{body}</StyledAboutBody>
          </Grid>
        </Grid>
      </StyledAboutContainer>
    </Grid>
  );
};
const StyledAboutContainer = styled.div`
  max-width: 500px;
`;

const StyledAboutHeader = styled(Typography)`
  font-style: italic;
  font-weight: bold;
  color: grey;
`;

const StyledAboutBody = styled(Typography)`
  text-align: left;
`;


export default About;
