import React from "react";
import styled from "styled-components";

import { Button, Grid, Typography } from "@material-ui/core";

/*
  ~ What it does? ~

  Displays an Address, Balance, and Wallet as one Account component,
  also allows users to log in to existing accounts and log out

  ~ How can I use? ~

  <Account
    address={address}
    localProvider={localProvider}
    userProvider={userProvider}
    mainnetProvider={mainnetProvider}
    price={price}
    web3Modal={web3Modal}
    loadWeb3Modal={loadWeb3Modal}
    logoutOfWeb3Modal={logoutOfWeb3Modal}
    blockExplorer={blockExplorer}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to the given address
  - Provide localProvider={localProvider} to access balance on local network
  - Provide userProvider={userProvider} to display a wallet
  - Provide mainnetProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide price={price} of ether and get your balance converted to dollars
  - Provide web3Modal={web3Modal}, loadWeb3Modal={loadWeb3Modal}, logoutOfWeb3Modal={logoutOfWeb3Modal}
              to be able to log in/log out to/from existing accounts
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
*/

export default function Account({
  address,
  userSigner,
  localProvider,
  mainnetProvider,
  price,
  minimized,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
}) {
  const modalButtons = [];

  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <Grid key="account" container direction="row" spacing={1} wrap={"nowrap"} alignItems="center" justifyContent="center">
          {/* <Grid item>
            <StyledCheckmark src={Checkmark} />
          </Grid> */}
          <Grid item>
            <StyledText>
              {address ? address.substring(0, 4) + " ..." + address.substring(address.length - 4) : ""}
            </StyledText>
          </Grid>
        </Grid>,
      );
    } else {
      modalButtons.push(
        <Button
          key="connect button"
          style={{ verticalAlign: "top", color: "#FF0000", textTransform: "none", fontSize: 21, fontWeight: "normal" }}
          variant="text"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          onClick={loadWeb3Modal}
        >
          <StyledConnectTypography>CONNECT WALLET</StyledConnectTypography>
        </Button>,
      );
    }
  }

  return <div>{modalButtons}</div>;
}

const StyledConnectTypography = styled(Typography)`
  && {
    padding: 20px;
    font-weight: bold;
    font-size: 24px;
    border-bottom: 3px solid white;
  }
`

const StyledText = styled(Typography)`
color: red;
padding-top: 20px;

  && {
    font-size: 21px;
    line-height: 36px;
    @media (max-width: 901px) {
      white-space: nowrap;
      font-size: 14px;
    }
  }
`;

const StyledCheckmark = styled.img`
margin-top: 20px;
  height: 21px;
  width: 21px;
`;
