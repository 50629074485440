import styled from "styled-components";
import { styled as muistyled, useTheme } from "@material-ui/styles";

import { Button, Grid, TextField, InputAdornment, AdornmenPopover, Typography, Fade, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
const { ethers } = require("ethers");



const RedeemDashboard = ({ isGenesis, myNumbas, backingPerToken, holderClaimable, writeContracts, tx }) => {
  const [selected, setSelected] = useState(null);
  const backingPerTokenValue = backingPerToken ? ethers.utils.formatEther(backingPerToken) : 0;

  const myNumbasValue = myNumbas ? myNumbas.map(x => parseInt(x.toString())) : [];
  myNumbasValue.sort(function (a, b) {
    return a - b;
  });
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <HolderClaimable holderClaimable={holderClaimable} />
      </Grid>
      <Grid item>
        <ClickableMyNumbas myNumbasValue={myNumbasValue} selected={selected} setSelected={setSelected} />
      </Grid>

      <Grid item>
        <ClaimAndBurn
          backingPerTokenValue={backingPerTokenValue}
          selectedNumba={selected}
          writeContracts={writeContracts}
          backingPerToken={backingPerToken}
          tx={tx}
        />
      </Grid>
    </Grid>
  );
};

const ClickableMyNumbas = ({ myNumbasValue, selected, setSelected }) => {
  return (
    <StyledMyNumbasContainer>
      <Grid container>
        {myNumbasValue.map(x => (
          <Grid item key={x}>
            <ClickableNumba numba={x} selected={selected} setSelected={setSelected} />
          </Grid>
        ))}
      </Grid>
    </StyledMyNumbasContainer>
  );
};


const ClickableNumba = ({ numba, selected, setSelected }) => {
  if (numba == selected) {
    return <StyledActiveNumba>{numba}</StyledActiveNumba>;
  }
  return <StyledNumba onClick={() => setSelected(numba)}>{numba}</StyledNumba>;
};

const HolderClaimable = ({ readContracts, useContractReader, holderClaimable }) => {
  const holderClaimableValue = holderClaimable ? ethers.utils.formatEther(holderClaimable) : 0;
  return <div>Total Claimable: {holderClaimableValue} Ξ</div>;
};


const ClaimAndBurn = ({ writeContracts, tx, selectedNumba, backingPerTokenValue }) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {selectedNumba ? <i>You will receive {backingPerTokenValue} Ξ</i> : <i> Click to select a NFT to burn </i>}
      </Grid>

      <Grid item>
        <StyledButton
          color={"secondary"}
          variant="outlined"
          disabled={!selectedNumba}
          onClick={async () => {
            /* look how you call setPurpose on your contract: */
            /* notice how you pass a call back for tx updates too */

            const result = tx(writeContracts.Numba.claimAndBurn(selectedNumba), update => {
              console.log("📡 Transaction Update:", update);
              if (update && (update.status === "confirmed" || update.status === 1)) {
                console.log(" 🍾 Transaction " + update.hash + " finished!");
                console.log(
                  " ⛽️ " +
                    update.gasUsed +
                    "/" +
                    (update.gasLimit || update.gas) +
                    " @ " +
                    parseFloat(update.gasPrice) / 1000000000 +
                    " gwei",
                );
              }
            });
            console.log("awaiting metamask/web3 confirm result...", result);
            console.log(await result);
          }}
        >
          Claim Backing And Burn Numba
        </StyledButton>
      </Grid>
    </Grid>
  );
};


const StyledNumba = styled.span`
  cursor: pointer;
  padding: 8px;
`;

const StyledActiveNumba = styled.span`
  cursor: pointer;
  padding: 8px;
  border: 1px solid white;
`;

const StyledMyNumbasContainer = styled.div`
  padding: 30px;
`;

const StyledButton = styled(Button)`
  border: #ff0000;
`;


export default RedeemDashboard;
