import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import "./index.css";

const subgraphUri = "http://localhost:8000/subgraphs/name/scaffold-eth/your-contract";
const theme = createTheme();

const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <App subgraphUri={subgraphUri} />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);


