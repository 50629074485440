import styled from 'styled-components'
import {
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import { useState } from 'react';


const Admin = ({ readContracts, useContractReader, writeContracts, tx }) => {
    const [whitelistAddresses, setWhitelistAddresses] = useState("");
    const [whitelistAmounts, setWhitelistAmounts] = useState("");
    const [newOwner, setNewOwner] = useState("");
    const [baseUri, setBaseUri] = useState("");

    const handleWhitelistAddressInput = (e) => {
        const value = e.target.value
        setWhitelistAddresses(value)
        
    }

    const handleWhitelistAmountInput = (e) => {
        const value = e.target.value
        setWhitelistAmounts(value)
    }

    const handleNewOwnerInput = (e) => {
        const value = e.target.value
        setNewOwner(value)
    }


    const handleBaseUriInput = (e) => {
        const value = e.target.value
        setBaseUri(value)
    }
    
    return(
        <StyledDiv>
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                    <h3>Transfer Ownership</h3>
                    </Grid>
                    <Grid item>
                        <TextField
                            color={"secondary"}
                                      inputProps={{ style: { textAlign: "center", color: "#FFFFFF", fontSize: 16, width: 90 } }}

                            label="new owner"
                            onChange={handleNewOwnerInput}
                            value={newOwner}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={async () => {
                            /* look how you call setPurpose on your contract: */
                            /* notice how you pass a call back for tx updates too */
                            const result = tx(writeContracts.GMFrens.transferOwnership(newOwner), update => {
                                console.log("📡 Transaction Update:", update);
                                if (update && (update.status === "confirmed" || update.status === 1)) {
                                console.log(" 🍾 Transaction " + update.hash + " finished!");
                                console.log(
                                    " ⛽️ " +
                                    update.gasUsed +
                                    "/" +
                                    (update.gasLimit || update.gas) +
                                    " @ " +
                                    parseFloat(update.gasPrice) / 1000000000 +
                                    " gwei",
                                );
                                }
                            });
                            console.log("awaiting metamask/web3 confirm result...", result);
                            console.log(await result);
                            }}
                        >
                            transfer ownership
                        </Button>
                    </Grid>


                </Grid>

            </Grid>


            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                    <h3>Set Base URI</h3>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="new owner"
                            onChange={handleBaseUriInput}
                            value={baseUri}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={async () => {
                            /* look how you call setPurpose on your contract: */
                            /* notice how you pass a call back for tx updates too */
                            const result = tx(writeContracts.GMFrens.setBaseURI(baseUri), update => {
                                console.log("📡 Transaction Update:", update);
                                if (update && (update.status === "confirmed" || update.status === 1)) {
                                console.log(" 🍾 Transaction " + update.hash + " finished!");
                                console.log(
                                    " ⛽️ " +
                                    update.gasUsed +
                                    "/" +
                                    (update.gasLimit || update.gas) +
                                    " @ " +
                                    parseFloat(update.gasPrice) / 1000000000 +
                                    " gwei",
                                );
                                }
                            });
                            console.log("awaiting metamask/web3 confirm result...", result);
                            console.log(await result);
                            }}
                        >
                            set base uri
                        </Button>
                    </Grid>


                </Grid>

            </Grid>

            <Grid item>
                <Button
                    onClick={async () => {
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(writeContracts.GMFrens.togglePresale(), update => {
                        console.log("📡 Transaction Update:", update);
                        if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                            " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                        }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    }}
                >
                    toggle Presale
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={async () => {
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(writeContracts.GMFrens.toggleSale(), update => {
                        console.log("📡 Transaction Update:", update);
                        if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                            " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                        }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    }}
                >
                    toggle Sale
                </Button>
            </Grid>

            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <h3>Edit Whitelist</h3>
                    </Grid>

                    <Grid item>
                        <i>comma separated values</i>
                    </Grid>

                    <Grid item>
                        <TextField
                            label="whitelist addresses"
                            onChange={handleWhitelistAddressInput}
                            value={whitelistAddresses}
                            rows={4}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="whitelist amounts"
                            onChange={handleWhitelistAmountInput}
                            value={whitelistAmounts}
                            rows={4}
                            multiline
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={async () => {
                            /* look how you call setPurpose on your contract: */
                            /* notice how you pass a call back for tx updates too */
                            const addresses = whitelistAddresses.split(",");
                            const amounts = whitelistAmounts.split(",").map(x => parseInt(x))
                            const result = tx(writeContracts.GMFrens.editPresale(addresses, amounts), update => {
                                console.log("📡 Transaction Update:", update);
                                if (update && (update.status === "confirmed" || update.status === 1)) {
                                console.log(" 🍾 Transaction " + update.hash + " finished!");
                                console.log(
                                    " ⛽️ " +
                                    update.gasUsed +
                                    "/" +
                                    (update.gasLimit || update.gas) +
                                    " @ " +
                                    parseFloat(update.gasPrice) / 1000000000 +
                                    " gwei",
                                );
                                }
                            });
                            console.log("awaiting metamask/web3 confirm result...", result);
                            console.log(await result);
                            }}
                        >
                            Edit Whitelist
                        </Button>
                        </Grid>
                </Grid>
            </Grid>

        </Grid>

        </StyledDiv>

    )
}

const StyledDiv = styled.div`
    padding-top: 200px;
`


export default Admin;